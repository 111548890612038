$colors: (
    "bgColor": #404060,
    "bgColor2": #ffd369,
    "textNBorder": #404060,
    "white": #ffffff,
    "color-background-base": #f0f2f5,
    "color-primary": #1a2a3a,
    "color-secondary": #c5c6cc,
    "color-accent": #4a5a6a,
    "color-invoice-fail": #ffe6e6,
    "color-invoice-fail-text": #ef8888,
    "transparent": "transparent",
    "color-white": #ffffff,
    "color-black": #000000,
    "color-selected": #1b73e8,
    "color-selected-light": #E6F6FF,

    "color-background-primary": #DDE2E0,
    "color-background-secondary": #e5e6ee,
    "color-background-accent": #e8ecef,
    "color-background-notifications": #ffffff,
    "color-background-notifications-selected": #404060,
    "color-background-btn": #aaa,
    "color-background-btn-secondary": #c5c6cc,
    "color-background-billing-header": #f5f6f7,

    "color-border-primary": #b5b6bb,
    "color-border-secondary": #c5c6cc,
    "color-border-accent": #e5e6ee,
    "color-border-overlay": #d5d6dd,
    "color-border-dark": #7a8a9a,
    "color-border-button": #404060,
    "color-notification": #aeaeba,
    "color-completed": #3ED39B,

    "color-dark-green": #3ED39B,
    "color-light-green": #eefff7,
    "color-dark-yellow": #ffd369,
    "color-light-yellow": #fff3d5,

    "color-rejected": #FF6666,
    "color-pending": #ffd369,
    "color-failure": #404060,
    "shadow-base": "0 1px 4px 2px rgb(64 64 96 / 10%)",
    "color-text-header": #3a4a5a,
    "color-active-item": #c4f9df,
    "color-background-popover": #F1F2F5,
    "color-custom": #7a8a9a,
    "color-icon": #aeaeba,
    "color-sidebar-icon": #9ba9ba,
    //"color-custom": #050505,
    "color-header-text": #7a8a9a,
    "color-card-text": #9ba9ba,
    "color-close":#FF6666,
    "color-table-odd":#f5f7f9,
    "color-table-even":#ffffff,

    "color-background-asset": #F9C0AB,
    "color-background-sub-asset": #A8CD89,
    "color-background-part": #FFCCEA,
    "color-highlight-asset": #FEF6F2,
    "color-highlight-sub-asset": #F2F8EE,
    "color-highlight-part": #FFF8FC,


    //category colors
    'category-color1': #FF6666,
    'category-color2': #55557B,
    'category-color3': #F0D717,
    'category-color4': #34B7EF,
    'category-color5': #4374F2,
    'category-color6': #00BD56,
    'category-color7': #FF90B3,
    'category-color8': #9966FF,
    'category-color9': #3BEBE4,
    'category-color10': #010000,
    'category-color11': #AC9969,
    'category-color12': #85EF47,
);

:root {
    @each $key, $value in $colors {
        --#{$key}: #{$value};
    }
}
@import '../../node_modules/ng-zorro-antd/style/entry.less';
@import "../../node_modules/ng-zorro-antd/button/style/entry.less";
@import "../../node_modules/ng-zorro-antd/layout/style/entry.less";
@import "../../node_modules/ng-zorro-antd/tooltip/style/entry.less";
@import "../../node_modules/ng-zorro-antd/modal/style/entry.less";
@import "../../node_modules/ng-zorro-antd/form/style/entry.less";
@import "../../node_modules/ng-zorro-antd/button/style/entry.less";
@import "../../node_modules/ng-zorro-antd/input/style/entry.less";
@import "../../node_modules/ng-zorro-antd/input-number/style/entry.less";
@import "../../node_modules/ng-zorro-antd/tabs/style/entry.less";
@import "../../node_modules/ng-zorro-antd/date-picker/style/entry.less";
@import "../../node_modules/ng-zorro-antd/message/style/entry.less";
@import "../../node_modules/ng-zorro-antd/table/style/entry.less";
@import "../../node_modules/ng-zorro-antd/drawer/style/entry.less";
@import "../../node_modules/ng-zorro-antd/radio/style/entry.less";
@import "../../node_modules/ng-zorro-antd/select/style/entry.less";
@import "../../node_modules/ng-zorro-antd/slider/style/entry.less";
@import "../../node_modules/ng-zorro-antd/popconfirm/style/entry.less";
@import "../../node_modules/ng-zorro-antd/tag/style/entry.less";
@import "../../node_modules/ng-zorro-antd/checkbox/style/entry.less";
@import "../../node_modules/ng-zorro-antd/switch/style/entry.less";
@import "../../node_modules/ng-zorro-antd/menu/style/entry.less";
@import "../../node_modules/ng-zorro-antd/divider/style/entry.less";
@import "../../node_modules/ng-zorro-antd/tree-select/style/entry.less";
@import "../../node_modules/ng-zorro-antd/breadcrumb/style/entry.less";
@import "../../node_modules/ng-zorro-antd/popover/style/entry.less";
@import "../../node_modules/ng-zorro-antd/collapse/style/entry.less";
@import "../../node_modules/ng-zorro-antd/spin/style/entry.less";
@import "../../node_modules/ng-zorro-antd/notification/style/entry.less";
@import "../../node_modules/ng-zorro-antd/card/style/entry.less";
@import "../../node_modules/ng-zorro-antd/segmented/style/entry.less";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";

@primary-color: #1a2a3a;
@root-entry-name: default;
@font-family: "Open Sans", sans-serif;
@body-background: var(--color-white);
@text-color: var(--color-accent);
@table-header-bg: var(--color-background-accent);
@menu-item-active-bg: var(--color-background-accent);
@table-header-color: var(--color-accent);
@table-border-color: #c5c6cc;
@select-border-color: var(--color-border-secondary);
@primary-color-hover: var(--color-border-secondary);
@primary-color-active: var(--color-border-secondary);
@input-number-hover-border-color: var(--color-border-secondary);
@input-hover-border-color: var(--color-border-secondary);
@input-icon-hover-color: var(--color-border-secondary);
@input-border-color: var(--color-border-secondary);

@layout-body-background: var(--color-background-accent);

.ant-layout {
  background: var(--color-background-accent) !important;
}

.ant-table-column-title {
  font-size: 14px !important;
  font-weight: 700;
  text-align: center;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn-background-ghost,
.ant-btn-background-ghost:hover,
.ant-btn-background-ghost:focus,
.ant-btn-background-ghost:active {
  border: 1px solid var(--color-border-secondary);
  color: var(--color-accent);
  background: var(--color-background-secondary);
}

.ant-btn {
  border: 1px solid var(--color-border-secondary);
  color: var(--color-accent);
  background: var(--color-background-secondary);
}

.ant-table-wrapper {
  height: 100%;
}

.ant-table-cell {
  text-align: center;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.ant-table {
  font-size: 14px !important;
  border-radius: 0px;
}

.ant-table,
.ant-table-container,
.ant-table-body,
nz-spin {
  height: 100%;
}

.ant-table-cell {
  text-align: center !important;

  // &.action-column {
  //   text-align: left !important;
  // }

  &.left-align-column {
    text-align: left !important;
  }
}

.ant-table-thead>tr>th {
  font-weight: 600 !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th:last-child,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td:last-child,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:last-child,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:last-child {
  border-right: none !important;
}

.ant-table-container table>thead>tr:first-child th:first-child,
.ant-table-container {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: none;
}

.ant-table-bordered {
  .ant-table-thead>tr>th {
    border-bottom: none;
    font-weight: 700 !important;
    font-size: 14px;
  }
}

.ant-table-header,
.nz-table-hide-scrollbar {
  border-top: 1px solid var(--color-border-secondary) !important;
  border-bottom: 1px solid var(--color-border-secondary) !important;
  border-left: 1px solid var(--color-border-secondary) !important;
  border-right: 1px solid var(--color-border-secondary) !important;
  background-color: var(--color-background-accent) !important;
}

.ant-table-body {
  border-left: 1px solid var(--color-border-secondary) !important;
  border-right: 1px solid var(--color-border-secondary) !important;
  border-bottom: 1px solid var(--color-border-secondary) !important;
  background-color: var(--color-white) !important;

}

// .ant-table.ant-table-bordered > .ant-table-container {
//   border-right: 1px solid var(--color-border-secondary) !important;
//   border-bottom: 1px solid var(--color-border-secondary) !important;
// }
// .ant-table-expanded-row-fixed {
//   width: fit-content !important;
// }
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none !important;
}

.ant-table.ant-table-bordered>.ant-table-container {
  border-left: none;
  background: var(--color-background-accent);
}

// .ant-table-expanded-row-fixed {
//   margin: -16px 0px !important;
//   width: 100% !important;
//   padding: 16px 0px !important;
//   position: relative !important;
//   .ant-table-cell {
//     padding: 16px 0px;
//   }
//   .ant-table-row-indent,.ant-table-row-expand-icon {
//     margin-left: 16px !important;
//     margin-right: 16px !important;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container {
//     border-right: none !important;
//     border-bottom: none !important;
//   }
// }




// tr.ant-table-expanded-row > td,
// tr.ant-table-expanded-row:hover > td {
//   background: #fff;
// }

.ant-pagination,
.ant-table-pagination {
  //border-top: 1px solid var(--color-border-secondary);
  align-items: center;
  height: 60px !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: var(--color-background-accent) !important;

  &::after {
    height: 59px !important;
  }

  &:last-child {
    padding-right: 16px !important;
  }

  .ant-pagination-prev>button,
  .ant-pagination-next>button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ant-pagination-item-active {
    border-color: var(--color-accent) !important;
  }
}

.ant-drawer-header {
  height: 60px !important;
  padding: 16px 0px !important;
  border-top: 1px solid var(--color-border-secondary);
  border-bottom: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-popover) !important;
}

.ant-drawer-header-title {
  height: inherit;
}

.ant-drawer-title {
  height: inherit;
}

.ant-drawer-body,
.ant-layout-sider {
  padding: 0;
  background: var(--color-background-accent) !important;
}

.ant-drawer-body {
  background: var(--color-background-popover) !important;

}

.ant-input {
  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-picker {
  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.ant-input-affix-wrapper {
  &:focus {
    box-shadow: none !important;
  }
}

// .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//   border-right-width: 0px;
// }

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper:hover {
  border-right-width: 0px;
}

.ant-switch {
  color: var(--color-border-secondary) !important;

  &.ant-switch-checked {
    background-color: var(--color-selected) !important;
  }

  background-color: currentColor !important;
}

.ant-switch-checked:focus {
  box-shadow: none;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.ant-select-dropdown {
  border: 1px solid var(--color-border-overlay) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
}

.ant-card {
  border: 1px solid var(--color-border-secondary);

  .ant-card-head {
    padding: 0px 16px;
    color: var(--color-accent);
    border-bottom-color: var(--color-border-secondary);
    background-color: var(--color-background-accent);
    margin-bottom: 0px;
    border-bottom: none;

    // margin-right: -0.5px;
    .ant-card-head-wrapper {
      height: 48px !important;

      .ant-card-head-title {
        display: flex !important;
        align-items: center;
        height: 100%;
        padding: 0;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.ant-modal-footer {
  padding: 12px 16px !important;
}

.ant-layout-header {
  height: 60px !important;
}

.ant-select-dropdown {
  padding: 0 !important;
}

.ant-input-number-focused {
  border: none !important;
  box-shadow: none !important;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--color-selected-light) !important;
  color: #000 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-selected-light) !important;
  color: #000 !important;
}

.custom-select-option .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.custom-select-option nz-option-item:first-child {
  padding-top: 10px !important;
}

.ant-radio-button-wrapper {
  height: 40px !important;
  color: #7a8a9a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--color-border-secondary) !important;
}

.ant-radio-input:focus+.ant-radio-inner {
  box-shadow: none !important;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: none !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: var(--custom-selected) !important;
  width: 0px !important;
  padding: 0px !important;
  border-color: transparent !important;
  content: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--custom-selected) !important;
  width: 0px !important;
  padding: 0px !important;
  top: 0 !important;
  left: 0 !important;
  content: none !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 0px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
  border-color: transparent !important;
  border: none !important;
  background: var(--color-selected) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  border-color: transparent !important;
}

.ant-popover-message>.anticon {
  color: #ff5555;
}

.ant-input-number-input-wrap,
.ant-input-number-input {
  height: 100%;
  text-align: center;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-selected);
}

.ant-tabs-ink-bar {
  background-color: var(--color-selected);
}

// .ant-input:hover {
//   border-right-width: 0px !important;
// }

// .ant-tabs > .ant-tabs-nav {
//   width: 66%;
//   position: fixed !important;
//   z-index: 10 !important;
//   background: var(--color-background-popover) !important;
// }

.ant-tabs-content-holder {
  margin-left: -24px;
  max-height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-tabs-content {
  padding: 0px 0px 24px 0px;
}

.ant-card-body {
  padding: 0;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
  //color: var(--color-white) !important;
  background: var(--color-background-accent) !important;
  border-color: var(--color-border-secondary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #c0cff6 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background-color: var(--color-selected) !important;
}

.ant-picker-footer {
  .ant-picker-ranges {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: var(--color-selected);
}

.ant-dropdown-menu-item-selected {
  color: #fff !important;
  background-color: #c0cff6 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color-selected) !important;
  border-color: var(--color-selected) !important;
}

.ant-notification-notice {
  &.success {
    background-color: var(--color-completed) !important;
  }

  &.warning {
    background-color: var(--color-pending) !important;
  }

  &.error {
    background-color: var(--color-rejected) !important;
  }

  .ant-notification-notice-icon,
  .ant-notification-close-icon,
  .ant-notification-notice-description {
    color: var(--color-white) !important;
  }

  .ant-notification-notice-message {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: var(--color-white) !important;
  }

  .ant-notification-notice-icon {
    display: flex;
    align-items: center;
    height: 70%;
  }

  .ant-notification-notice-description {
    font-weight: 600 !important;
  }
}

.work-order-filter-container {

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 600 !important;
  }
}

.ant-drawer-title {
  color: var(--color-accent) !important;
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 32px;


    .ant-modal-confirm-body {
      display: flex;
      flex-direction: column;
      align-items: center;


      .anticon {
        margin-right: 0;
        font-size: 36px;
        color: var(--color-close) !important;
        margin-bottom: 10px;
      }

      .ant-modal-confirm-title {
        color: var(--color-failure);
        font-size: 20px;
        font-weight: 700;
      }

      .ant-modal-confirm-content {
        margin-left: 0 !important;
        font-size: 18px;
      }


    }

    .ant-modal-confirm-btns {
      width: 100%;
      text-align: center;

      .ant-btn {
        font-size: 18px;
        font-weight: 600;
        height: 36px;
        padding-inline: 24px;

        &:first-child {
          margin-right: 16px;
        }
      }
    }

  }
}

.bg-table-even-row {
  &:hover {
    td {
      background-color: var(--color-table-even) !important;
    }
  }

  .ant-table-cell {
    background-color: var(--color-table-even) !important;
  }

}

.bg-table-odd-row {
  &:hover {
    td {
      background-color: var(--color-table-odd) !important;
    }
  }

  .ant-table-cell {
    background-color: var(--color-table-odd) !important;
  }


}

.work-order-asset-tree-dropdown {
  .ant-select-tree-checkbox {
    margin: 10px 8px 0 0 !important;
  }
}

@segmented-bg: #bad4f8;
@segmented-hover-bg: #bad4f8;
@segmented-selected-bg: var(--color-selected);

@segmented-label-color: var(--color-selected);
@segmented-label-hover-color: var(--color-selected);

.ant-segmented-item {
  transition: none;

  // &:hover,
  // &focus {
  //   color: #fff;
  // }

  &.ant-segmented-item-selected {
    transition: none;
    background-color: var(--color-selected);
    color: #fff;

    &:hover {
      color: #fff;

    }
  }
}

// .ant-segmented {

//   //background-color: var(--color-selected)
//   &:hover {
//     color: #000000 !important;

//   }
// }

// .ant-segmented-item-selected {
//   background-color: var(--color-selected);
//   //color: #fff;

//   &:hover {
//     color: #fff;

//   }
// }

.ant-layout-sider.left-nav {
  padding: 0;
  background: var(--color-background-accent) !important;
  border-left: 0 !important;
  //border-right: 1px solid var(--color-border-secondary) !important;

  .left-nav-menu-item {
    // .left-nav-text {}

    &.selected,
    &:hover {
      .left-nav-text {
        color: var(--color-selected) !important;
      }
    }

    //background-color: #fff;
  }

  .ant-layout-sider-children {
    background-color: #fff;
    border-right: 1px solid var(--color-border-secondary);
  }
}

.ant-table-placeholder {
  .ant-table-cell {
    border: none !important
  }

  .ant-table-expanded-row-fixed {
    &::after {
      border: none !important;
    }
  }
}

.work-order-form-table {
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        background-color: var(--color-white);
      }
    }
  }
}
@import 'scss/variables';
@import 'scss/tailwind';
@import 'scss/custom';
@import 'material-symbols';

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
    border: 0.5px solid var(--color-border-secondary);
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
}

.text-14 {
    font-size: 14px !important;
}
.asset-wos-drawer {
    .ant-drawer-header {
        border-bottom: 0;
    }
}
.work-orders-drawer {
    .ant-table-body {
      max-height: calc(100vh - 184px) !important;
    }
}
.asset-filter {
    min-width: 250px;

    .ant-select-single {
        .ant-select-selector .ant-select-selection-search-input {
          padding-top: 12px;
        }
       .ant-select-selection-placeholder, .ant-select-selection-item {
            padding-top: 5px;
        }
        .anticon-down {
            display: none;
        }
        .ant-select-clear {
            color: var(--color-rejected);
            font-size: 16px;
            opacity: 1;
            margin-top: -8px;
        }
    }
}
.part-selection {
    display: none;
    right: 10px;
    position: absolute;
    font-size: 12px;
    padding-top: 3px;
    border-radius: 3px;
}
.inventory-slider {
    .part-td {
        .part-selection {
            display: inline-block;
            border: none !important;
        }
    }
    .ant-drawer-header {
        background-color: #e8ecef !important;
    }
    .ant-table-body {
        max-height: calc(-240px + 100vh) !important;
    }
}
.error-field {
    &.ant-input-status-error,
    &.ant-select-status-error .ant-select-selector {
        border-style: solid !important;
        border-width: 1px !important;
    }
}
.ant-select-multiple .ant-select-selection-item {
    max-width: 80%;
}
/* .custom-field-select nz-select-top-control{
    padding-top: 25px !important;
} */
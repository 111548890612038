.selected-icon {
  //filter: invert(52%) sepia(73%) saturate(2076%) hue-rotate(203deg) brightness(99%) contrast(90%);
  color: var(--color-selected);

}

.white-icon {
  //filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(196deg) brightness(101%) contrast(103%);
  color: var(--color-white) !important;
}

.header-icon {
  //color: #698aef;
  //filter: invert(54%) sepia(11%) saturate(590%) hue-rotate(169deg) brightness(98%) contrast(87%);
  color: var(--color-icon);
}


.material-symbols-rounded {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;

  &.selected {
    /* filter: invert(45%) sepia(97%) saturate(815%) hue-rotate(203deg) brightness(98%) contrast(92%); */
    @extend .selected-icon;

  }

  //filter: invert(75%) sepia(6%) saturate(143%) hue-rotate(191deg) brightness(89%) contrast(86%);
  color:var(--color-icon);

  &.tab-icon {
    color: var(--color-sidebar-icon);
  }

  &:hover {
    /*  filter: invert(45%) sepia(97%) saturate(815%) hue-rotate(203deg) brightness(98%) contrast(92%); */
    @extend .selected-icon;
  }

  &.no-hover {
    &:hover {
      //filter: invert(75%) sepia(6%) saturate(143%) hue-rotate(191deg) brightness(89%) contrast(86%) !important;
      color: var(--color-icon);
    }
  }

  &.selected-red {
    &:hover {
      color: var(--color-rejected);
      //filter: invert(87%) sepia(39%) saturate(3431%) hue-rotate(304deg) brightness(102%) contrast(87%);
    }
  }

  /* Not using */
  /* &.gray-c5c6cc {
    color: #c5c6cc;
    //filter: invert(82%) sepia(7%) saturate(157%) hue-rotate(194deg) brightness(97%) contrast(92%);

    &:hover {
      color: #c5c6cc;
      //filter: invert(82%) sepia(7%) saturate(157%) hue-rotate(194deg) brightness(97%) contrast(92%) !important;
    }

    &.selected {
      //filter: invert(70%) sepia(31%) saturate(623%) hue-rotate(98deg) brightness(108%) contrast(91%) !important;

      &:hover {
        //filter: invert(70%) sepia(31%) saturate(623%) hue-rotate(98deg) brightness(108%) contrast(91%) !important;
      }
    }
  } */

  &.white-icon {
    /* filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%); */
    //filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7484%) hue-rotate(218deg) brightness(103%) contrast(101%);

    @extend .white-icon;

    &:hover {
      @extend .white-icon;
      //filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%) !important;
    }
  }

  &.green-icon {
    color: var(--color-completed);
    //filter: invert(70%) sepia(18%) saturate(1323%) hue-rotate(106deg) brightness(99%) contrast(87%);

    &:hover {
      color: var(--color-completed);
      //filter: invert(70%) sepia(18%) saturate(1323%) hue-rotate(106deg) brightness(99%) contrast(87%) !important;
    }
  }

  &.close-icon {
    //color: #698aef;
    //filter: invert(68%) sepia(9%) saturate(5761%) hue-rotate(312deg) brightness(91%) contrast(126%) !important;
    color: var(--color-rejected);

    &:hover {
      color: var(--color-rejected);
      //filter: invert(68%) sepia(9%) saturate(5761%) hue-rotate(312deg) brightness(91%) contrast(126%) !important;

    }
  }
}

.left-nav-menu-item {

  &.selected,
  &:hover {
    .material-symbols-rounded {
      @extend .selected-icon;
    }
  }
}

.pb-40 {
  padding-bottom: 160px !important;
}

.form-image-container {
  width: 85px;
  height: 85px;
  color: var(--color-custom);

  &.img-btn {
    width: 150px;
    height: 40px;
  }

  &:hover>span {
    color: var(--color-selected);
    @extend .selected-icon;
  }
}

.chart-image-container {
  width: 150px;
  height: 125px;
  color: var(--color-custom);

  &:hover>span {
    color: var(--color-selected);
    @extend .selected-icon;
  }
}

.bg-filter-selected {
  // background-color: #646496 !important;
  // color: #4a5a6a !important;
  color: #fff;
  // text-decoration: underline;
}

.border-transparent {
  border-color: transparent;
}

.master-table {
  .ant-table-tbody {
    .ant-table-measure-now>td {
      height: 0px !important;
      padding: 0px !important;
    }
  }

  .ant-table-header,
  .ant-table-thead {
    height: 60px !important;
  }

  // .ant-table-thead>tr>th {
  //   border-bottom: none !important;
  // }

  // .ant-table.ant-table-bordered>.ant-table-container {
  //   border-left: none !important;
  // }

  // .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
  // .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  //   border-top: none !important;
  // }
  .ant-table-expanded-row {
    .nz-disable-td {
      padding: 0px !important;
    }
  }

  .asset-sub-table-tr>td {
    //border-bottom: none !important;
  }

  .asset-sub-table-tr {
    :only-child>td {
      //border-bottom: 1px solid var(--color-border-secondary) !important;
    }

    .sub-asset-part-table-tr:last-child {
      .ant-table-tbody>tr:last-child>td {
        border-bottom: 1px solid var(--color-border-secondary) !important;
      }
    }
  }

  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
    overflow: hidden !important;
  }

  .ant-table-tbody>tr>td {
    height: 40px !important;
    padding: 7px 16px;
    font-size: 14px;
  }

  // .sub-asset-part-table-tr {
  // .ant-table .ant-table-tbody>tr>td {
  //   border-top: 1px solid var(--color-border-secondary) !important;
  //   border-bottom: none !important;
  // }

  // .ant-table .ant-table-tbody>tr:last-child>td {
  //   border-bottom: none !important;
  // }
  // }

  .ant-table-expanded-row-fixed {
    margin: 0px !important;
    padding: 0px !important;
    width: inherit !important;

    &::after {
      border-right: none !important;
    }

    .ant-table-row-indent+.ant-table-row-expand-icon {
      margin-right: 0px !important;
    }

    .ant-table-cell {
      border-right: 1px solid var(--color-border-secondary) !important;
      padding: 7px 16px;
    }

    .ant-table-cell:last-child {
      border-right: none !important;
    }

    .ant-table-cell-fix-left-last::after {
      width: 0px !important;
    }

    .ant-table-wrapper {
      .ant-table {
        margin: 0 !important;
      }

      // .ant-table .ant-table-tbody>tr:first-child>td {
      //   border-bottom: none !important;
      // }
      // .ant-table .ant-table-tbody>tr:last-child>td {
      //   border-top: 1px solid var(--color-border-secondary) !important;
      // }
      // .ant-table .ant-table-tbody>tr:only-child>td {
      //   border-top: none !important;
      // }
    }
  }

}

.type-edit-field {
  height: 50px !important;
}

.location-menu {
  height: calc(100% - 60px);
  padding-bottom: 100px;
  // background-color: #eaedf5 !important;
}

.location-add {
  background-color: #eaedf5;
}

.custom-select-control {

  .ant-select-selector,
  .ant-select-selection-search,
  .ant-select-selection-search-input {
    height: 65px !important;
  }

  .ant-select-selection-item {
    padding-top: 28px !important;
  }
}

.multi-select {
  .ant-select-selector {
    min-height: 65px;
    padding-top: 28px;
    padding-bottom: 4px;
    border: none !important;
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    top: 70%;
  }

  .ant-select-selection-search {
    display: none;
  }
}

.custom-tree-select-control {
  .ant-select-selection-search-input {
    padding-top: 20px !important;
  }
}

.custom-control {
  height: 65px !important;

  &.disabled {
    cursor: not-allowed;
    background-color: #F5F5F5 !important;
    ;
  }
}

.custom-text-area-control {
  min-height: 65px !important;
  max-height: 140px !important;

  &.ant-input {
    height: 65px;
  }
}

.custom-text-area-work-order-control {
  min-height: 80px !important;
  max-height: 140px !important;

  &.ant-input {
    height: 80px;
  }
}

.custom-margin {
  margin-top: -1.0px !important;
}

.custom-margin-part {
  margin-top: -1.0px !important;
}

.image-details-container {
  width: 85px;
  height: 85px;
}

.asset-add-button:hover {
  div>* {
    @extend .selected-icon;
  }
}

.maintenance-type-container {
  margin-top: -0.5px !important;

  .ant-radio-button-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-radio-button-wrapper {
    border: none;
    height: 47px;
    background-color: var(--color-background-active);
  }

  .ant-radio-button-wrapper-checked {
    background-color: var(--color-selected);
    color: var(--color-white) !important;

    &:hover {
      color: var(--color-white);
    }
  }

  .maintenance-number-control {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-input-number-input {
      text-align: center;
    }
  }

  .ant-input-number-focused {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-input-number {
    border: none !important;
  }
}

.custom-rounded {
  .ant-select-selector {
    border-radius: 0px !important;
  }
}

.home-logo {
  height: 120px;
}

.home-location-container {
  height: calc(100% - 120px) !important;
}

.custom-content:hover {
  color: var(--color-selected) !important;

  >span,
  >label>span {
    color: var(--color-selected) !important;
    @extend .selected-icon;
  }
}

.custom-field-label {
  font-size: 13px;
  opacity: 0.7;
  color: var(--color-primary);

  &:hover {
    color: var(--color-selected);
  }

  &.selected {
    color: var(--color-selected);
  }
}

.custom-field-label-icon {
  font-size: 20px !important;
  opacity: 0.8;
  line-height: 1 !important;
  padding: 2px 8px;
}

/* .ant-form-item-has-success {
  .custom-field-label {
    color: var(--color-selected);
  }

  .custom-field-label-icon {
    @extend .selected-icon;
  }
} */
.tab {
  .tab-icon {
    @extend .header-icon;
  }

  &.selected {
    .tab-name {
      color: var(--color-white) !important;
    }

    .tab-icon {
      @extend .white-icon;
    }

    &:hover {
      .tab-name {
        color: var(--color-white) !important;
      }

      .tab-icon {
        @extend .selected-icon;
      }
    }
  }

  &:hover {
    .tab-name {
      color: var(--color-selected) !important;
      ;
    }

    .tab-icon {
      @extend .selected-icon;
    }
  }

}

.custom-maintenance-lable {
  color: #7a8a9a !important;
}

.settings-card-height {
  height: 400px !important;
  max-height: 400px !important;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.custom-label {
  color: var(--color-custom) !important;
}

.inventory-form-select-field {
  .ant-select-selector {
    border-radius: 0px !important;
  }
}

.risk-switch {
  .ant-switch {
    &.ant-switch-checked {
      background-color: var(--color-rejected) !important;
    }
  }
}

.checklist-switch {
  .ant-switch {
    &.ant-switch-checked {
      background-color: var(--color-selected) !important;
    }
  }
}

.workorder-asset-tree-control {
  .ant-select-selector {
    border: none !important;
    border-radius: 0 !important;
    height: 100% !important;
  }
}

.custom-field-height {
  max-height: 65px !important;
}

.work-order-chat-comment-field {
  height: 82px !important;
}

.inventory-part-control {
  height: 63px;
}

.custom-card {
  .ant-card-head .ant-card-head-wrapper {
    height: 60px !important;
  }

  .ant-card-body {
    height: 350px !important;
    max-height: 350px !important;
  }

  &.full-h-card {
    .ant-card-body {
      height: calc(100% - 120px) !important;
      max-height: 100% !important;
    }
  }
}

.cal-month-view {
  max-height: 100%;
  overflow: auto;

  .cal-days {
    border-color: var(--color-border-secondary);
  }

  .cal-day-cell {
    min-height: 96px;
  }

  .cal-day-cell:not(:last-child) {
    border-right-color: var(--color-border-secondary);
  }
}

.cal-week-view {
  max-height: 100%;
  overflow: auto;
}

.select-border-0 {
  border: 0 !important;

  nz-select-top-control {
    border: 0 !important;
  }
}

.cal-month-view .cal-header .cal-cell {
  padding: 16px 0;
}

.location-menu-nav:hover {
  div {
    height: 100%;
    display: flex !important;
    align-items: center;
    background-color: var(--color-white);

    &>* {
      @extend .selected-icon;
    }

    .location-menu-item>* {
      @extend .selected-icon;
      color: var(--color-selected);
      opacity: 1 !important;
    }
  }
}

.warehouse-menu-nav:hover {
  div {
    height: 100%;
    display: flex !important;
    align-items: center;
    background-color: var(--color-white);

    &>* {
      @extend .selected-icon;
    }

    .warehouse-menu-item>* {
      @extend .selected-icon;
      color: var(--color-selected);
      opacity: 1 !important;
    }
  }
}

.work-order-asset-tree-dropdown {
  .ant-select-tree-node-content-wrapper {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-select-tree-switcher {
    line-height: 30px;
  }
}

.work-order-user-dropdown {
  /* .cdk-virtual-scroll-viewport {
    height: 100% !important;
  } */

  .ant-select-item {
    padding: 10px 12px;
  }
}

.theme-color-btn {
  background: var(--color-selected) !important;
  color: var(--color-white) !important;
  opacity: 0.9;

  .material-symbols-rounded {
    color: var(--color-white) !important;
  }

  .custom-label {
    color: var(--color-white) !important;
  }

  &:hover {
    .material-symbols-rounded {
      color: var(--color-white) !important;
    }

    .custom-label {
      color: var(--color-white) !important;
    }

    opacity: 1 !important;
  }
}

.theme-table-container {
  box-shadow: 0 2px 10px 0 rgba(51, 51, 79, .02);
}

.overflow-auto,
.overflow-y-auto {
  scrollbar-color: #c1c1c1 transparent;
}